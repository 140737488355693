
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import SalesService from "@/core/services/car/SalesService";
import { roles } from "@/store/stateless/store";
// import { SalesTargetList } from "@/store/modules/car/SalesModule";
// import InvoiceService from "@/core/services/car/InvoiceService";
/*import LocalStorageServices from "@/core/services/LocalStorageServices";
    import useClipboard from "vue-clipboard3";*/

interface BodyParam {
  year: number;
}

export default defineComponent({
  name: "sales-target-list",
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    let salesTarget = ref<any>();
    let bodyParameters = {} as BodyParam;
    bodyParameters = { year: new Date().getFullYear() };
    let years: number[] = [];

    const sales = ref([]);

    onMounted(() => {
      if(!roles.salesTarget.includes(roles.userId())) window.location.href = '/#/404'
      
      
      sales.value = store.state.SalesModule.salesTargetList;
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("View & Edit Sales Target", []);
      generateArrayOfYears();
      getSalesTargetData(store, bodyParameters);
    });

    const getSalesTargetData = (store, bodyParameters) => {
      Promise.all([
        SalesService.getSalesTargetList(store, bodyParameters),
      ]).then((data) => {
        //console.log(store.getters.getSalesTarget);
        salesTarget.value = store.getters.getSalesTarget;
        //
      });
    };

    const generateArrayOfYears = () => {
      let max = new Date().getFullYear();
      let min = max - 5;
      for (let i = max; i >= min; i--) {
        years.push(i);
      }
      //getYear();
    };

    const getYear = () => {
      bodyParameters.year = parseInt(
        (document.getElementById("year") as HTMLSelectElement).value
      );

      getSalesTargetData(store, bodyParameters);
    };

    const updateSalesTarget = (data) => {
      SalesService.saveSalesTarget(data).then((data) => {
        console.log(data);
        //getSalesTargetData(store, bodyParameters);
      });
    };

    return {
      roles,
      bodyParameters,
      getSalesTargetData,
      salesTarget,
      generateArrayOfYears,
      years,
      updateSalesTarget,
      getYear,
      sales,
    };
  },
});
