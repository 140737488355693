import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

/**
 * @description service to call HTTP request via Axios
 */
class SalesService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        SalesService.vueInstance = app;
    }

    public static getSalesTargetList(store, bodyParameters, index=-1) {
        bodyParameters.index = index;
        return new Promise<any>((resolve, reject) => {
        store.dispatch(Actions.GET_SALES_TARGET_LIST, bodyParameters)
            .then((data) =>{
                resolve(data)
            })
            .catch((response) => {
                console.log(response)
            })
        });
    }

    public static saveSalesTarget(data){
        return new Promise((resolve, reject) => {
            store.dispatch(Actions.SAVE_SALES_TARGET,data)
                .then((data) => {
                    router.push({name:'sales-target-list'});
                    resolve(data);
                })
                .catch((error)=>{
                    // reject(error)
                })
        });
    }

    public static setSalesTargetList(store, elem){
        store.state.salesTargetList = computed(() => {
            return store.getters.setSalesTargetList;
        });
    }

    public static getLeadsTargetList(store, bodyParameters, index=-1){
        bodyParameters.index = index;
        return new Promise<any>((resolve, reject) => {
            store.dispatch(Actions.GET_LEADS_TARGET_LIST, bodyParameters)
                .then((data) =>{
                    resolve(data)
                })
                .catch((response) => {
                    console.log(response)
                })
        });
    }

    public static setLeadsTargetList(store, elem){
        store.state.leadsTargetList = computed(() => {
            return store.getters.setLeadsTargetList;
        });
    }

    public static saveLeadsTarget(data){
        return new Promise((resolve, reject) => {
            store.dispatch(Actions.SAVE_LEADS_TARGET,data)
                .then((data) => {
                    router.push({name:'leads-target-list'});
                    resolve(data);
                })
                .catch((error) => {
                    //reject(error);
                })
        });
    }
}

export default SalesService;